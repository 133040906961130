import { Box, Button, Container, Typography } from '@mui/material'
import './style.css'
import { ButtonAnimated } from '../../../components/ButtonAnimated'

export function Matchmaker() {
    return (
        <Box className="newSection" sx={{ mt: '40px' }}>
            <Container maxWidth="lg" className="newSectionContainer"
                style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/banner/banner-joinville.jpeg)`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }} >
                <Typography variant="h3" component="h2" gutterBottom className="sectionTitle" color="white">
                    Encontre seu lar perfeito
                </Typography>
                <Typography variant="h5" component="h2" gutterBottom className="sectionSubtitle" color="white">
                    Conectamos você aos melhores negócios imobiliários em Balneário Camboriú e Joinville
                </Typography>
                <Box className="sectionButtons">
                    <Button
                        variant="contained"
                        color="primary"
                        className='contact'
                        onClick={() => window.open('https://wa.me/+5547991683239?text=Olá! Gostaria de mais informações.', '_blank')}
                    >
                        Contato
                    </Button>
                    <ButtonAnimated>
                        Saiba Mais
                    </ButtonAnimated>
                </Box>
            </Container>
        </Box >
    )
}