import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Box, Container, Typography } from "@mui/material";
import api from "../../../services/api";
import { useNavigate } from "react-router-dom";

const MoreCarousel = ({ title, subTitle, id }: any) => {
  const [images, setImages] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await api.get(`list-sections/${id}`);
        // Flatten images from all properties into a single array with secure_id and property_id
        const allImages = response.data.reduce((acc:any, item:any) => {
          const itemImages = item.images.map((img:any) => ({
            ...img,
            secure_id: item.secure_id,
            property_id: img.property_id
          }));
          return [...acc, ...itemImages];
        }, []);
        setImages(allImages);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    if (id) {
      fetchImages();
    }
  }, [id]);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: images.length <= 1 ? 1 : 2.5,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: images.length <= 1 ? 1 : 2.5,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]
  };

  const handleImageClick = (secure_id:any) => {
    navigate(`/detalhes-do-imovel/${secure_id}`);
  };
  
  return (
    <Container maxWidth="lg" sx={{marginBottom: '60px'}}>
      <Typography variant="h3" component="h2" gutterBottom sx={{ color: "#c7fd06", mt: 15, fontWeight: 700 }}>
      {title}
      </Typography>
      <Slider {...settings}>
        {images.map((img:any, index) => (
          <Box key={index} className="carouselImageContainer"  onClick={() => handleImageClick(img.secure_id)}>
            <img src={`${img.path}`} alt={`Imagem ${index + 1}`} />
          </Box>
        ))}
      </Slider>
    </Container>
  );
};

export default MoreCarousel;
