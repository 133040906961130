import React, { useEffect, useState, useRef, ChangeEvent, FormEvent } from 'react';
import { Box, Button, Typography, TextField, Checkbox, FormControlLabel, InputLabel, Snackbar, Alert, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { styled } from '@mui/material/styles';
import api from '../../../services/api';
import './style.css';

interface Office {
    name: string;
    latitude_id: string;
}

interface FormData {
    nome: string;
    celular: string;
    email: string;
    latitude_id: string;
}

const DarkDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        backgroundColor: '#fff',
        color: '#c7fd06',
        border: '1px solid #c7fd06',
        width: '100%', // Adicionado para garantir que ocupe toda a largura disponível
        maxWidth: '600px', // Definindo um máximo para evitar que fique muito grande
        margin: '0 auto' // Centralizar o Dialog
    }
}));

const DarkTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
        backgroundColor: '#fff',
        color: '#c7fd06',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#c7fd06',
    },
    '& .MuiFilledInput-underline:after': {
        borderBottomColor: '#c7fd06',
    },
    '& .MuiFilledInput-underline:before': {
        borderBottomColor: 'rgba(255, 255, 255, 0.42)',
    },
    '&:hover .MuiFilledInput-underline:before': {
        borderBottomColor: 'rgba(255, 255, 255, 0.87)',
    }
}));

const StyledContainer = styled(Box)(({ theme }) => ({
    border: '1px solid white',
    padding: '20px',
    textAlign: 'center',
    margin: '20px auto',  // Changed to 'auto' to center horizontally
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '1090px',  // Added max-width for better responsiveness
    width:'75%',
     background: `url(${process.env.PUBLIC_URL}/assets/images/properties/floripa.jpg)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
}));

export function Contact() {
    const [checked, setChecked] = useState<string>('');
    const [formData, setFormData] = useState<FormData>({
        nome: '',
        celular: '',
        email: '',
        latitude_id: ''
    });
    const [offices, setOffices] = useState<Office[]>([]);
    const nomeRef = useRef<HTMLInputElement>(null);
    const celularRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const [celular, setCelular] = useState<string>('');
    const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>('');
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    useEffect(() => {
        const fetchOffices = async () => {
            try {
                const response = await api.get('list-offices');
                setOffices(response.data);
            } catch (error) {
                console.error('Failed to fetch offices', error);
            }
        };

        fetchOffices();
    }, []);

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        const office = offices.find((office) => office.name === event.target.name);
        setChecked(event.target.name);
        setFormData({
            ...formData,
            latitude_id: office ? office.latitude_id : ''
        });
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const validateForm = (): boolean => {
        if (!formData.nome) {
            setSnackbarMessage("Por favor, preencha o campo nome.");
            setOpenSnackbar(true);
            nomeRef.current?.focus();
            return false;
        } else if (!formData.celular) {
            setSnackbarMessage("Por favor, preencha o campo celular.");
            setOpenSnackbar(true);
            celularRef.current?.focus();
            return false;
        } else if (!formData.email) {
            setSnackbarMessage("Por favor, preencha o campo email.");
            setOpenSnackbar(true);
            emailRef.current?.focus();
            return false;
        } else if (!formData.latitude_id) {
            setSnackbarMessage("Por favor, selecione um escritório.");
            setOpenSnackbar(true);
            return false;
        }
        return true;
    };

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        if (validateForm()) {
            try {
                const response = await api.post('send-lead', formData);
                console.log('Formulário enviado:', response.data);
                setSnackbarMessage("Formulário enviado com sucesso!");
                setOpenSnackbar(true);
            } catch (error) {
                setSnackbarMessage("Erro ao enviar o formulário.");
                setOpenSnackbar(true);
                console.error('Failed to send form data', error);
            }
        }
    };

    const handleCelularChange = (event: ChangeEvent<HTMLInputElement>) => {
        const originalValue = event.target.value.replace(/\D/g, '');
        let maskedValue = '';

        if (originalValue.length > 10) {
            maskedValue = originalValue.replace(/^(\d{2})(\d{5})(\d{1,4}).*$/, '($1) $2-$3').substr(0, 15);
        } else if (originalValue.length > 6) {
            maskedValue = originalValue.replace(/^(\d{2})(\d{4})(\d{0,4}).*$/, '($1) $2-$3');
        } else if (originalValue.length > 2) {
            maskedValue = originalValue.replace(/^(\d{2})(\d{0,4}).*$/, '($1) $2');
        } else if (originalValue.length > 0) {
            maskedValue = originalValue.replace(/^(\d{0,2}).*$/, '($1');
        } else {
            maskedValue = '';
        }

        setCelular(maskedValue);
        setFormData(prevFormData => ({
            ...prevFormData,
            celular: maskedValue
        }));
    };

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <Box>
            {/* Banner Section */}
            <StyledContainer style={{ backgroundPosition:'center', objectPosition: 'center'}} sx={{height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', color: '#c7fd06' }}>
                <Typography variant="h4" sx={{ mb: 2 }}>
                    Entre em contato
                </Typography>
                <Button variant="contained" sx={{ backgroundColor: '#c7fd06', color: 'black', '&:hover': { backgroundColor: '#b3e505' } }} onClick={handleClickOpenDialog}>
                    Preencha o formulário
                </Button>
            </StyledContainer>

            {/* Dialog with Form */}
            <DarkDialog open={openDialog} onClose={handleCloseDialog} maxWidth="lg" fullWidth>
                <DialogTitle>Entre em contato</DialogTitle>
                <DialogContent>
                    <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <InputLabel sx={{ color: '#c7fd06', mt: 2, display: 'block' }}>Nome</InputLabel>
                        <DarkTextField
                            inputRef={nomeRef}
                            name="nome"
                            value={formData.nome}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            variant="filled"
                        />
                        <InputLabel sx={{ color: '#c7fd06', mt: 2, display: 'block' }}>Celular</InputLabel>
                        <DarkTextField
                            inputRef={celularRef}
                            name="celular"
                            value={celular}
                            onChange={handleCelularChange}
                            fullWidth
                            margin="normal"
                            variant="filled"
                        />
                        <InputLabel sx={{ color: '#c7fd06', mt: 2, display: 'block' }}>Email</InputLabel>
                        <DarkTextField
                            inputRef={emailRef}
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            variant="filled"
                        />
                        <Box sx={{ display: 'grid', justifyContent: 'start', mb: 2 }}>
                            {offices.map((office, index) => (
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox
                                            checked={checked === office.name}
                                            onChange={handleCheckboxChange}
                                            name={office.name}
                                            sx={{ color: '#c7fd06', '&.Mui-checked': { color: '#c7fd06' } }}
                                        />
                                    }
                                    label={office.name}
                                    sx={{ color: '#c7fd06' }}
                                />
                            ))}
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} sx={{ color: '#c7fd06' }}>Cancelar</Button>
                    <Button type="submit" onClick={handleSubmit} sx={{ backgroundColor: '#c7fd06', color: 'black', '&:hover': { backgroundColor: '#b3e505' } }}>
                        Enviar
                    </Button>
                </DialogActions>
            </DarkDialog>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={handleCloseSnackbar} severity="warning" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

        </Box>
    );
}