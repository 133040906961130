import React from 'react';
import { Card, CardContent, Typography, Avatar, Rating, Grid, Box, Container } from '@mui/material';

const TestimonialCard = ({ name, position, company, quote }: any) => {
  const initials = name.split(' ').map((n: any) => n[0]).join('');

  return (
    <Card sx={{ bgcolor: 'white', color: 'black', m: 2, border: 1, borderColor: 'white', p: 2 }}>
      <CardContent>
        <Rating name="read-only" value={5} readOnly />
        <Typography variant="body1" sx={{ mt: 2 }}>{quote}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 5 }}>
          <Avatar sx={{ bgcolor: 'white', color: 'black', mr: 2 }}>{initials}</Avatar>
          <Box>
            <Typography variant="body2">{name}</Typography>
            <Typography variant="body2">{position}, {company}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

const Testimonials = () => {
  const testimonials = [
    { name: 'Ana Martins', position: 'Gerente de Projetos', company: 'TecnoCorp', quote: 'Desde o primeiro contato até o fechamento do contrato, a equipe da VEXT demonstrou profissionalismo e dedicação. Encontrei a casa dos meus sonhos e todo o processo foi transparente e eficiente.' },
    { name: 'Carlos Souza', position: 'Advogado', company: 'Souza & Associados', quote: 'A VEXT superou todas as minhas expectativas! O atendimento personalizado e a atenção aos detalhes fizeram toda a diferença. Recomendo fortemente para quem está procurando um serviço imobiliário de qualidade.' },
    { name: 'Mariana Lima', position: 'Designer Gráfica', company: 'Criativa Design', quote: 'Eu estava muito ansiosa para comprar meu primeiro apartamento e a equipe da VEXT me guiou por cada etapa, esclarecendo todas as minhas dúvidas. Sinto-me extremamente satisfeita com a compra e o suporte recebido.' },
  ];

  return (
    <Container sx={{ bgcolor: 'white', color: 'black' }}>
      <CardContent sx={{ textAlign: 'center', borderBottom: 1, borderColor: 'transparent' }}>
        <Typography variant="h4" sx={{ fontFamily: 'Red Hat Display', fontSize: '48px', fontWeight: 700, lineHeight: '58px', color: '#c7fd06' }}>
          Depoimentos
        </Typography>
        <Typography variant="subtitle1" sx={{ fontFamily: 'Red Hat Display',  lineHeight: '58px' }}>
        Experiências reais de quem confiou em nós
        </Typography>
      </CardContent>
      <Grid container spacing={2} sx={{ p: 2 }}>
        {testimonials.map((testimonial, index) => (
          <Grid item xs={12} sm={12} md={4} key={index}>
            <TestimonialCard {...testimonial} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Testimonials;

