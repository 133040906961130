import React from 'react';
import Slider from 'react-slick';
import { Box, Container, Paper, useMediaQuery, useTheme } from '@mui/material';
import './style.css';

const Comercial = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const slides = [
    {
      image: "https://api-homol.vextimob.com.br/images/banner/easy-payment.png"
    },
    {
      image: "https://api-homol.vextimob.com.br/images/banner/easy-payment.png"
    }
  ];

  const slidesMobile = [
    {
      image: "https://api-homol.vextimob.com.br/images/banner/easy-payment-mobile.png"
    },
    {
      image: "https://api-homol.vextimob.com.br/images/banner/easy-payment-mobile.png"
    }
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const selectedSlides = isMobile ? slidesMobile : slides;

  return (
    <Box className="benefits-section" sx={{ overflow: 'hidden' }}>
      <Container maxWidth="lg" sx={{ position: 'relative', padding: '0 !important', mb: 4 }}>
        <Slider {...settings}>
          {selectedSlides.map((slide, index) => (
            <Box key={index} sx={{ p: 0 }}>
              <Paper elevation={3} sx={{ borderRadius: '0', height: '600px', backgroundImage: `url(${slide.image})`, backgroundSize: 'cover', backgroundPosition: 'center', color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
              </Paper>
            </Box>
          ))}
        </Slider>
      </Container>
    </Box>
  );
};

export default Comercial;