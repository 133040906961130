import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Box, Container, Typography, Paper, Button } from "@mui/material";
import './style.css';
import api from "../../../services/api";
import { useNavigate } from "react-router-dom";

const Carousel = ({ title, subTitle, id }: any) => {
  const [images, setImages] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await api.get(`list-sections/${id}`);
        // Flatten images from all properties into a single array with secure_id and property_id
        const allImages = response.data.reduce((acc:any, item:any) => {
          const itemImages = item.images.map((img:any) => ({
            ...img,
            secure_id: item.secure_id,
            property_id: img.property_id,
            friendly_title: item.friendly_title,
            property_type: item.property_type,
            city: item.city,
            state: item.state,
            sale_price: item.sale_price,
            area: item.area,
            bedrooms: item.bedrooms,
            suites: item.suites,
            parking_spots: item.parking_spots
          }));
          return [...acc, ...itemImages];
        }, []);
        setImages(allImages);
        console.log("🚀 ~ fetchImages ~ allImages:", allImages)
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    if (id) {
      fetchImages();
    }
  }, [id]);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: images.length <= 1 ? 1 : 2.5,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: images.length <= 1 ? 1 : 2.2,
          slidesToScroll: 1,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.05,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]
  };
  
  const handleImageClick = (secure_id:any) => {
    navigate(`/detalhes-do-imovel/${secure_id}`);
  };

  return (
    <Container maxWidth="lg" sx={{ marginBottom: '60px', backgroundColor: '#fff', padding: '20px', borderRadius: '10px' }}>
      <Typography variant="h3" component="h2" gutterBottom sx={{ color: "black", mt: 15 }}>
        {title}
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom sx={{ color: "black", fontSize: 18, mt: 4, mb: 10 }}>
        {subTitle}
      </Typography>
      <Slider {...settings}>
        {images.map((img:any, index) => (
          <Box key={index} className="carouselImageContainer">
            <Paper elevation={3} sx={{ p: 0, height: '500px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', overflow: 'hidden', border: '2px solid #c7fd06', borderRadius: '8px', backgroundColor: '#fff' }}>
              <Box>
                <img src={`${img.path}`} alt={`Imagem ${index + 1}`} style={{ width: '100%', borderRadius: '8px 8px 0 0' }} />
              </Box>
              <Box style={{ padding: '10px', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6" component="h3" sx={{ mt: 2, color: '#c7fd06' }}>
                  {img.friendly_title}
                </Typography>
                <Typography variant="body2" sx={{ color: 'black' }}>
                  {img.property_type} - {img.city}, {img.state}
                </Typography>
                <Typography variant="body2" sx={{ color: 'black' }}>
                  {img.area} m² - {img.bedrooms} quartos - {img.suites} suítes - {img.parking_spots} vagas
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: 'bold', mt: 1, color: 'black' }}>
                  {parseFloat(img.sale_price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </Typography>
                <Button 
                  variant="contained" 
                  sx={{ mt: 'auto', backgroundColor: '#c7fd06', color: 'black', '&:hover': { backgroundColor: '#b3e505' } }} 
                  onClick={(e) => {
                    e.stopPropagation();
                    handleImageClick(img.secure_id);
                  }}
                >
                  Ver mais
                </Button>
              </Box>
            </Paper>
          </Box>
        ))}
      </Slider>
    </Container>
  );
};

export default Carousel;